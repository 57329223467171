<template>
  <v-app>
    <dashboard-core-app-bar v-model="expandOnHover" />

    <dashboard-core-drawer :expand-on-hover.sync="expandOnHover" />

    <transition
      mode="in-out"
      @before-enter="routeBeforeEnter"
      @enter="routeEnter"
    >
      <dashboard-core-view id="dashboard-core-view" style="padding-top: 85px;"/>
    </transition>
  </v-app>
</template>

<script>
  import gsap from 'gsap'

  export default {
    name: 'DashboardIndex',

    components: {
      DashboardCoreAppBar: () => import('@/components/_view_handler/dashboard/AppBar'),
      DashboardCoreDrawer: () => import('@/components/_view_handler/dashboard/Drawer'),
      DashboardCoreView: () => import('@/components/_view_handler/dashboard/View'),
    },

    data: () => ({
      expandOnHover: false,
    }),

    methods: {
      routeBeforeEnter(el) {
        gsap.from(el, {
        })
      },
      routeEnter(el) {
        gsap.to(el, {
        })
      },
    }
  }
</script>

<style>
  /* #dashboard-core-view {
    zoom: 80%
  } */
</style>
